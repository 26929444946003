<div class="panel-header">
  <mat-icon
    [matTooltip]="getInspectTooltip()"
    class="icon-button"
    [ngClass]="{'selected-inspect-button': isSelectingMode()}"
    (click)="toggleSelectingMode()"
    >frame_inspect</mat-icon
  >
  <mat-icon
    [matTooltip]="getHotReloadTooltip()"
    class="icon-button"
    (click)="hotReload()"
    >refresh</mat-icon
  >
  <div
    class="panel-button"
    [ngClass]="{'selected-panel-button': selectedPanel === Panel.Components}"
    (click)="selectEditorPanel()"
  >
    Components
  </div>
  <div
    class="panel-button"
    [ngClass]="{'selected-panel-button': selectedPanel === Panel.Logs}"
    (click)="selectLogsPanel()"
  >
    Logs
  </div>
</div>
<div class="panel-body">
  <mesop-editor-panel
    [componentConfigs]="componentConfigs"
    [ngClass]="{'hide-panel': selectedPanel !== Panel.Components}"
  ></mesop-editor-panel>

  <mesop-logs-panel
    [ngClass]="{'hide-panel': selectedPanel !== Panel.Logs}"
  ></mesop-logs-panel>
</div>
