<mat-sidenav-container class="container">
  <mat-sidenav-content #sidenavContent class="content">
    <mesop-shell></mesop-shell>
    <div #dragHandle class="resize-handle" [hidden]="!showDevTools()"></div>
    <div class="editor-button-container">
      <button class="editor-button" mat-icon-button (click)="toggleDevTools()">
        <mat-icon>code</mat-icon>
      </button>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    #sidenav
    [opened]="showDevTools()"
    mode="side"
    position="end"
    class="right-sidenav"
  >
    <mesop-dev-tools
      *ngIf="showDevTools()"
      [componentConfigs]="componentConfigs()"
    ></mesop-dev-tools>
  </mat-sidenav>
</mat-sidenav-container>
