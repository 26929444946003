<div>
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <cdk-tree-node
      cdkTreeNodePadding
      cdkTreeNodePaddingIndent="10"
      *matTreeNodeDef="let node"
      class="node leaf-node"
    >
      @if(node.name === "type") {
      <div class="type-row">
        <span class="type-chip" [title]="node.value"
          >{{node.value.slice(1, node.value.length - 1)}}
        </span>
        @if(node.duration) {
        <span class="duration-chip" title="Duration since previous log event">
          {{node.duration}}ms
        </span>
        }
      </div>
      }@else {
      <span class="key">{{node.name}}</span>:
      <span class="value" [title]="node.value">{{node.value}}</span>
      }
    </cdk-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <cdk-tree-node
      cdkTreeNodePadding
      cdkTreeNodePaddingIndent="10"
      *matTreeNodeDef="let node;when: hasChild"
      class="node"
      matTreeNodeToggle
    >
      <button class="button" [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
        </mat-icon>
      </button>
      <span class="key">{{node.name}}</span>:
      <span class="value composite-value" [title]="node.value"
        >{{node.value}}</span
      >
    </cdk-tree-node>
  </cdk-tree>
</div>
