<div class="logs-panel">
  <cdk-virtual-scroll-viewport [itemSize]="10" class="viewport" #virtualScroll>
    <div
      *cdkVirtualFor="let log of logDataSource; trackByFn"
      class="log-element"
    >
      <mesop-object-tree [object]="log"></mesop-object-tree>
    </div>
  </cdk-virtual-scroll-viewport>
  <div id="log-end"></div>
</div>
