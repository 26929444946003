<div class="fields-section">
  @for(field of getRegularFields(); track $index) {
  <div
    class="field-block"
    [ngClass]="{'field-block-active': this.hoveredFieldName === field.getName()}"
    (mouseenter)="onMouseenter(field.getName()!)"
    (mouseleave)="clearHoveredFieldName()"
  >
    @switch(field.getType()?.getTypeCase()) {@case(FieldTypeCase.INT_TYPE) {
    <label class="input-label">{{field.getName()}}</label>:
    <input
      readonly
      class="input"
      type="number"
      [attr.data-name]="field.getName()"
      [value]="getValueFor(field.getName()!)"
    />
    } @case(FieldTypeCase.FLOAT_TYPE) {
    <label class="input-label">{{field.getName()}}</label>:
    <input
      class="input"
      type="number"
      readonly
      [attr.data-name]="field.getName()"
      [value]="getValueFor(field.getName()!)"
    />
    } @case(FieldTypeCase.LIST_TYPE) {
    <div class="list-section">
      <div class="list-header">
        <label class="input-label">{{field.getName()}}</label>:
      </div>

      @for(prefixes of getPrefixesListForListField(field.getName()!); track
      $index) {
      <div class="struct-header">
        <span class="struct-name">[{{$index}}]</span>
      </div>
      <div class="struct-body" (mouseenter)="clearHoveredFieldName()">
        <mesop-editor-fields
          [fields]="getFieldsForListField(field)"
          [prefixes]="prefixes"
        ></mesop-editor-fields>
      </div>
      }
    </div>
    <!-- preserve for fmt -->
    } @case(FieldTypeCase.STRING_TYPE) { @if(field.getName() === "text") {
    <label class="input-label">{{field.getName()}}</label>:
    <textarea
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      class="textarea"
      [attr.data-name]="field.getName()"
      [value]="getValueFor(field.getName()!)"
    ></textarea>
    } @else {
    <label class="input-label">{{field.getName()}}</label>:
    <input
      class="input"
      readonly
      [attr.data-name]="field.getName()"
      [value]="getValueFor(field.getName()!)"
      [size]="getValueFor(field.getName()!).length || 10"
    />
    } } @case(FieldTypeCase.LITERAL_TYPE) {
    <label class="input-label">{{field.getName()}}</label>:
    <select [attr.data-name]="field.getName()" class="select">
      @for(literal of field.getType()!.getLiteralType()?.getLiteralsList();
      track $index) {
      <option
        [value]="$index"
        [selected]="isLiteralSelected(field.getName()!, literal)"
      >
        {{getLiteralValue(literal)}}
      </option>
      }
    </select>
    } @case(FieldTypeCase.STRUCT_TYPE) {
    <div class="struct-section">
      <div class="struct-header">
        <span class="struct-name">{{field.getName()}}</span>
      </div>
      <div class="struct-body" (mouseenter)="clearHoveredFieldName()">
        <mesop-editor-fields
          [fields]="field.getType()!.getStructType()!.getFieldsList()"
          [prefixes]="getPrefixFor(field.getName()!)"
        >
        </mesop-editor-fields>
      </div>
    </div>
    } @default {
    <div class="field-name">
      Error: unhandled field name {{field.getName()}}
    </div>
    } }
  </div>

  } @for(field of getBoolFields(); track $index) {
  <mat-checkbox
    [checked]="!!getValueFor(field.getName()!)"
    [value]="field.getName()!"
    disabled
    >{{field.getName()}}</mat-checkbox
  >
  }
  <div class="non-editable-section">
    @for(field of getNonTypedFields(); track $index) {
    <label class="input-label">{{field.getName()}}</label> (non-editable) }
  </div>
</div>
