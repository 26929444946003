<div>
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <cdk-tree-node
      cdkTreeNodePadding
      cdkTreeNodePaddingIndent="10"
      *matTreeNodeDef="let node"
      class="node leaf-node"
      #treeNode
    >
      <div
        class="node-container"
        [ngClass]="{'highlighted-node': highlightedNodes.has(node)}"
        (mouseenter)="onMouseenter(node)"
        (mouseleave)="onMouseleave(node)"
      >
        <div
          (click)="selectNode(node)"
          class="node-body"
          [ngClass]="{'selected-node': isNodeSelected(node)}"
        >
          <span class="name" [title]="node.text">{{node.text}}</span>
          <span class="label">{{node.label}}</span>
        </div>
      </div>
    </cdk-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <cdk-tree-node
      cdkTreeNodePadding
      cdkTreeNodePaddingIndent="10"
      *matTreeNodeDef="let node;when: hasChild"
      class="node"
      #treeNode
    >
      <div
        class="node-container"
        [ngClass]="{'highlighted-node': highlightedNodes.has(node)}"
        (mouseenter)="onMouseenter(node)"
        (mouseleave)="onMouseleave(node)"
      >
        <div
          class="node-body"
          (click)="selectNode(node)"
          [ngClass]="{'selected-node': isNodeSelected(node)}"
        >
          <button
            class="button"
            [attr.aria-label]="'Toggle ' + node.text"
            matTreeNodeToggle
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'arrow_drop_down' :
              'arrow_right'}}
            </mat-icon>
          </button>
          <span class="name" [title]="node.text">{{node.text}}</span>
          <span class="label">{{node.label}}</span>
        </div>
      </div>
    </cdk-tree-node>
  </cdk-tree>
</div>
