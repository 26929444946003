@if(config().getIsNativeTextarea()) {
<textarea
  [disabled]="config().getDisabled()"
  [placeholder]="config().getPlaceholder()!"
  [value]="config().getValue()"
  [readonly]="config().getReadonly()"
  [style]="getStyle()"
  (input)="onInput($event)"
  (blur)="onBlur($event)"
  [cdkTextareaAutosize]="config().getAutosize()"
  [cdkAutosizeMinRows]="config().getMinRows()"
  [cdkAutosizeMaxRows]="config().getMaxRows()"
  #autosize="cdkTextareaAutosize"
></textarea>
} @else {
<mat-form-field
  [hideRequiredMarker]="config().getHideRequiredMarker()"
  [color]="getColor()"
  [floatLabel]="getFloatLabel()"
  [appearance]="getAppearance()"
  [subscriptSizing]="getSubscriptSizing()"
  [hintLabel]="config().getHintLabel()!"
  [style]="getStyle()"
  ><mat-label *ngIf="config().getLabel()">{{config().getLabel()}}</mat-label>
  @if(config().getIsTextarea()) {
  <textarea
    matInput
    [disabled]="config().getDisabled()"
    [id]="config().getId()!"
    [placeholder]="config().getPlaceholder()!"
    [required]="config().getRequired()"
    [type]="config().getType()!"
    [value]="config().getValue()"
    [readonly]="config().getReadonly()"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
    [rows]="config().getRows()"
    [cdkTextareaAutosize]="config().getAutosize()"
    [cdkAutosizeMinRows]="config().getMinRows()"
    [cdkAutosizeMaxRows]="config().getMaxRows()"
  ></textarea>
  } @else {
  <input
    matInput
    [disabled]="config().getDisabled()"
    [id]="config().getId()!"
    [placeholder]="config().getPlaceholder()!"
    [required]="config().getRequired()"
    [type]="config().getType()!"
    [value]="config().getValue()"
    [readonly]="config().getReadonly()"
    (blur)="onBlur($event)"
    (input)="onInput($event)"
    (keyup)="onKeyUp($event)"
  />
  }
</mat-form-field>
}
