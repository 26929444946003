<div class="top-panel" #topPanel>
  @if(component()) {

  <div class="component-tree-title">Component Tree</div>
  <mesop-component-tree
    [component]="component()!"
    [selectedComponent]="getFocusedComponent()"
  >
  </mesop-component-tree>
  } @else { No component rendered. }
</div>

<div class="bottom-panel" #bottomPanel>
  <mat-divider></mat-divider>
  <div #dragHandle class="resize-handle"></div>
  @if(hasFocusedComponent()) {
  <div class="component-header">
    <span class="component-header-start">
      <span class="component-name"> {{getFocusedComponentName()}} </span>
      <a
        *ngIf="showDocLink()"
        [href]="getDocLink()"
        target="_blank"
        rel="noopener"
        ><mat-icon>ungroup</mat-icon></a
      >
    </span>
  </div>
  <div class="component-body">
    <mesop-editor-fields
      [fields]="getFields()"
      [prefixes]="[]"
    ></mesop-editor-fields>
  </div>
  } @else {
  <div class="empty-state-message">Select a component</div>
  }
</div>
